import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { getApiBaseUrl, handleErrors } from '~/api/common/common';
import { cookieUtils } from '~/utils/cookies';
import { redirectAxiosInterceptor } from '~/utils/redirectAxiosInterceptor';

export const userApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/user',
    withCredentials: true,
    validateStatus: (status) => status !== 401
  })

  cookieUtils.addAxiosBaseCookiesInterceptors(axiosBase)
  redirectAxiosInterceptor(axiosBase, config)

  return {
    async getUserFullName (userId: string) {
      
      const { data: payload } = await axiosBase.get('/get-user-full-name', {
        params: { userId }
      })

      return handleErrors(payload)
    },
  }
}