import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { getApiBaseUrl, handleErrors } from '~/api/common/common';
import { cookieUtils } from '~/utils/cookies';
import { redirectAxiosInterceptor } from '~/utils/redirectAxiosInterceptor';

export const resultApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/result',
    withCredentials: true,
    validateStatus: (status) => status !== 401
  })

  cookieUtils.addAxiosBaseCookiesInterceptors(axiosBase)
  redirectAxiosInterceptor(axiosBase, config)

  return {
    async getHistory () {
      const { data: payload } = await axiosBase.get('/get-history')

      return handleErrors(payload)
    },
    async getCurrentSessionResult () {
      const { data: payload } = await axiosBase.get('/get-current-session-result')

      return handleErrors(payload)
    }
  }
}
