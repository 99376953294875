import { defineStore } from "pinia";
import type { IUserDataJwtPayload } from '~/types/auth';
import { mockUserDataJwtPayload } from '~/mocks/auth.mock';

interface State {
  user: IUserDataJwtPayload | null;
}

const isLocalMode = false;

export const useAuthStore = defineStore("auth", {
  state: (): State => ({
    user: null,
  }),
  getters: {
    isAuthenticated: state => !!state.user
  },
  actions: {
    async getUser() {
      const api = useApi()
      if (isLocalMode) {
        console.log('-- Local mode is running! --')
        this.setUser(mockUserDataJwtPayload)
        return;
      }

      const { data: userData } = await useFetch('/server/api/users/current-user')

      if (!userData.value?.result) {
        this.user = null
        return;
      }
      
      this.setUser((userData.value.user) as IUserDataJwtPayload)
      const response = await api.userApi.getUserFullName(this.user?.userId)
      this.user.firstName = response.value?.firstName
      this.user.lastName = response.value?.lastName
    },
    setUser (user: IUserDataJwtPayload) {
      this.user = user;
    }
  },
});
