import { left, right } from '@sweet-monads/either';
import { isObject} from 'lodash-es';
import type { RuntimeConfig } from 'nuxt/schema';

export function getApiBaseUrl(config: RuntimeConfig) {
  return config.public.API_BASE_URL_SERVER;
}

export function getAuthApiBaseUrl(config: RuntimeConfig) {
  return config.public.AUTH_API_URL + '/v1';
}

export const handleErrors = (payload: unknown) => {
  // @ts-ignore
  if (!isObject(payload) || (isObject(payload) && 'error' in payload)) {

    return left(payload);
  }

  return right((payload as any).data);
};
