import { AxiosInstance } from 'axios';
import { RuntimeConfig } from 'nuxt/schema';

export const redirectAxiosInterceptor = (axiosBase: AxiosInstance, config: RuntimeConfig) => {
  axiosBase.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        window.location.href = `${config.public.AUTH_FRONTEND_URL}/passport/logic`;
      }
      return Promise.reject(error);
    }
  );
}