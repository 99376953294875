import { userApi } from '~/api/user.api'
import { sessionApi } from '~/api/session.api';
import { resultApi } from '~/api/result.api';

export const useApi = () => {
  const config = useRuntimeConfig()

  return {
    userApi: userApi(config),
    sessionApi: sessionApi(config),
    resultApi: resultApi(config),
  }
}

export type Api = ReturnType<typeof useApi>
