import type { RuntimeConfig } from "@nuxt/schema";
import axios from "axios";
import { getApiBaseUrl, handleErrors } from "~/api/common/common";
import { cookieUtils } from "~/utils/cookies";
import { redirectAxiosInterceptor } from "~/utils/redirectAxiosInterceptor";
import { getTimezoneOffset } from "~/utils/getTimezoneOffset";

export const sessionApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    baseURL: getApiBaseUrl(config) + "/session",
    withCredentials: true,
    validateStatus: (status) => status !== 401,
  });

  cookieUtils.addAxiosBaseCookiesInterceptors(axiosBase);
  redirectAxiosInterceptor(axiosBase, config);

  return {
    async getCurrentSession() {
      const { data: payload } = await axiosBase.get("/get-current-session", {
        params: {
          tzOffset: getTimezoneOffset(),
        },
      });

      return handleErrors(payload);
    },
    async submitAnswer(params: {
      answerIndex: number;
      answerNumber: number;
      tzOffset: number;
    }) {
      const { data: payload } = await axiosBase.put("/submit-answer", params);

      return handleErrors(payload);
    },
    async hasSession(): Promise<boolean> {
      const { data: payload } = await axiosBase.get("/has-session", {
        params: {
          tzOffset: getTimezoneOffset(),
        },
      });
      const handled = handleErrors(payload);

      return handled?.value?.hasSession;
    },
    async deleteAllUserActiveSessions() {
      const { data: payload } = await axiosBase.delete(
        "/delete-all-user-active-sessions",
      );
      return handleErrors(payload);
    },
    async completeTestTimeOver() {
      const { data: payload } = await axiosBase.post(
        "/complete-test-time-over",
      );
      return handleErrors(payload);
    }
  };
};
