import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as resultRXNKHYxfMUMeta } from "/usr/src/app/pages/result.vue?macro=true";
import { default as sign_45in7sEcJLcQCLMeta } from "/usr/src/app/pages/sign-in.vue?macro=true";
export default [
  {
    name: "main",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    meta: resultRXNKHYxfMUMeta || {},
    component: () => import("/usr/src/app/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "signIn",
    path: "/sign-in",
    meta: sign_45in7sEcJLcQCLMeta || {},
    component: () => import("/usr/src/app/pages/sign-in.vue").then(m => m.default || m)
  }
]